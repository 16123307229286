import './bootstrap';
import '../css/app.scss';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.js";

import { DefineComponent, createApp, h } from 'vue';
import { createInertiaApp, usePage } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import { LocationState } from './Models/LocationState';

new LocationState();

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Taxa.Nu';

createInertiaApp({
	title: (title: string) => `${title} - ${appName}`,
	resolve: (name: string) => <any>resolvePageComponent(
		`./Pages/${name}.vue`,
		/** @ts-ignore */
		import.meta.glob('./Pages/**/*.vue')
	),
	setup: ({ el, App, props, plugin }) => <any>createApp({ render: () => h(App, props) })
		.use(plugin)
		.use(ZiggyVue, 'Ziggy')
		.mount(el),
	progress: {
		color: '#4B5563',
	},
});

const oFetch = window.fetch;
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
let newCallReady = true;
window.fetch = async function(...args)
{
	while(!newCallReady) await sleep(1);
	newCallReady = false;
	let prom = await oFetch(...args);
	newCallReady = true;
	return prom;
}
