export class LocationState
{
	public static get location() : string {
		return location.pathname
	}

	private static _query: {[key: string]: any};
	public static query = new Proxy(this, {
		get: (target: any, name: string) => 
		{
			if(this._query[name] !== undefined) return this._query[name];
		},
		set: (target: any, name: string, value: any) =>
		{
			this._query[name] = value;
			return true;
		}
	})
	
	private static instance: LocationState;
	constructor()
	{
		if(LocationState.instance) return LocationState.instance;
		LocationState._query = LocationState.parseQueryParams(location.search);
		let oldPushState = history.pushState;
		history.pushState = function pushState(...args) {
			let ret = oldPushState.apply(this, args);
			window.dispatchEvent(new Event('pushstate'));
			window.dispatchEvent(new Event('locationchange'));
			return ret;
		};

		let oldReplaceState = history.replaceState;
		history.replaceState = function replaceState(...args) {
			let ret = oldReplaceState.apply(this, args);
			window.dispatchEvent(new Event('replacestate'));
			window.dispatchEvent(new Event('locationchange'));
			return ret;
		};

		window.addEventListener('popstate', () => {
			window.dispatchEvent(new Event('locationchange'));
		});

		LocationState.instance = this;
		window.addEventListener('locationchange', () => LocationState.instance.LocationChange());
	}
	

	public static parseQueryParams(queryString: string)
	{
		const queryParams: {[key:string]: any} = {};
		const paramsArr = queryString.substring(1).split('&');

		for (let i = 0; i < paramsArr.length; i++) {
			if( paramsArr[i] == "") continue;
			const param = paramsArr[i].split('=');
			const key = decodeURIComponent(param[0]).replace(/\[([\w\d]+)\]/g, '.$1');
			const value = this.fixValue(decodeURIComponent(param[1] || ''));
			const keysArr = key.split('.');
			let obj = queryParams;

			for (let j = 0; j < keysArr.length - 1; j++) {
				const k = keysArr[j];

				if (!obj[k]) {
					obj[k] = {};
				}

				obj = obj[k];
			}

			const lastKey = keysArr[keysArr.length - 1];

			if (lastKey in obj && Array.isArray(obj[lastKey])) {
				obj[lastKey].push(value);
			} else if (lastKey in obj) {
				obj[lastKey] = [obj[lastKey], value];
			} else {
				obj[lastKey] = value;
			}
		}
		return queryParams;
	}
	private static fixValue(value: string)
	{
		let num = Number(value);
		if(!isNaN(num)) return num;
		if(['on', 'true'].indexOf(value) !== -1) return true;
		if(['off', 'false'].indexOf(value) !== -1) return false;
		
		return value;
	}

	public static linkUpdate(e: MouseEvent)
	{
		if(e.target == undefined) return;
		let elm: HTMLAnchorElement = <HTMLAnchorElement>e.target;
		e.preventDefault();
		window.history.pushState(null, elm.href, elm.href);
	}

	private static callbacks: {[key:string]: ((value: any) => void)[]} = {};
	public static onParmChange(name: string, callback: (value: any) => void)
	{
		if(this.callbacks[name] == undefined) this.callbacks[name] = [];
		if(this.callbacks[name].indexOf(callback) !== -1) return;
		this.callbacks[name].push(callback);
	}


	public static getNameOf(name: string, object: {[key: string]: any})
	{
		let path = name.split('.');
		let current = object;
		for(let part of path)
		{
			if(current[part] === undefined) return false;
			current = current[part];
		}
		return [true, current];
	}
	public static updateValueOf(name: string, value: any, object: {[key: string]: any})
	{
		let path = name.split('.');
		let current = object;
		let lastIndex = path[path.length -1];
		for(let part of path)
		{
			if(part === lastIndex)
			{
				current[part] = value;
				return;
			}
			if(current[part] === undefined) current[part] = {};
			current = current[part];
		}
	}

	public static getParm(name: string)
	{
		return this.getNameOf(name, this._query);
	}
	private lastValue: {[key:string]: any} = {};
	private LocationChange()
	{
		LocationState._query = LocationState.parseQueryParams(location.search);
		for(let key in LocationState.callbacks)
		{
			let parm = LocationState.getParm(key);
			if(parm === false)
			{
				if(this.lastValue[key] !== undefined) this.lastValue[key] = undefined;
				continue;
			}
			if(parm[1] === this.lastValue[key]) continue;

			this.lastValue[key] = parm[1];
			for(let callback of LocationState.callbacks[key])
			{
				if(callback) callback(parm[1]);
			}
		}
	}
	public static object2Query(obj: {[key: string]: any}, prefix: string = ""): string
	{
		var str:string[] = [], p;
		for (p in obj) {
			if (obj.hasOwnProperty(p)) {
				var k = prefix ? prefix + "[" + p + "]" : p,
					v = obj[p];
				str.push((v !== null && typeof v === "object") ?
					this.object2Query(v, k) :
					k + "=" + v);
			}
		}
		return str.join("&");
	}
	public static getNewQuery(change: {[key: string]: any})
	{
		let newQuery = Object.assign({}, this._query);
		for(let name in change)
		{
			this.updateValueOf(name, change[name], newQuery);
		}
		return '?' + this.object2Query(newQuery);
	}
}